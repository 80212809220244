<template>
  <div class="top-header">
    <div class="site-title">
      Realtime live trading monitoring
    </div>
    <div class="header-date-time">
      <div class="date">
        <div class="icon"></div>
        {{ time | moment('DD-MM-yyyy') }}
      </div>
      <div class="time">
        <div class="icon"></div>
        {{ time | moment('HH:mm') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",

  data: () => ({
    time: new Date(),
    interval: null
  }),

  beforeDestroy() {
    clearInterval(this.interval)
  },

  created() {
    this.interval = setInterval(() => {
      this.time = new Date()
    }, 1000);
  },
}
</script>

<style scoped lang="scss">
.top-header {
  display: grid;
  grid-template-columns: 7fr 1fr;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .site-title {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    color: #E9BD42;
    text-align: center;
  }

  .header-date-time {
    width: 230px;
    color: #D9D9D9;
    display: flex;

    .date,
    .time {
      display: flex;
      align-items: center;

      .icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
    }

    .date {
      margin-right: 30px;

      .icon {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMiAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjY2NjcgMTIuMTY2NUgxLjMzMzMzVjQuODMzMTdIMTAuNjY2N004LjY2NjY3IDAuMTY2NTA0VjEuNDk5ODRIMy4zMzMzM1YwLjE2NjUwNEgyVjEuNDk5ODRIMS4zMzMzM0MwLjU5MzMzMyAxLjQ5OTg0IDAgMi4wOTMxNyAwIDIuODMzMTdWMTIuMTY2NUMwIDEyLjUyMDEgMC4xNDA0NzYgMTIuODU5MyAwLjM5MDUyNCAxMy4xMDkzQzAuNjQwNTczIDEzLjM1OTQgMC45Nzk3MTEgMTMuNDk5OCAxLjMzMzMzIDEzLjQ5OThIMTAuNjY2N0MxMS4wMjAzIDEzLjQ5OTggMTEuMzU5NCAxMy4zNTk0IDExLjYwOTUgMTMuMTA5M0MxMS44NTk1IDEyLjg1OTMgMTIgMTIuNTIwMSAxMiAxMi4xNjY1VjIuODMzMTdDMTIgMi4wOTMxNyAxMS40IDEuNDk5ODQgMTAuNjY2NyAxLjQ5OTg0SDEwVjAuMTY2NTA0IiBmaWxsPSIjRDlEOUQ5Ii8+Cjwvc3ZnPgo=) no-repeat;
      }
    }

    .time {
      .icon {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuOTk4NyAxMi44MzM1QzguNDEzMTkgMTIuODMzNSA5Ljc2OTc0IDEyLjI3MTYgMTAuNzY5OSAxMS4yNzE0QzExLjc3MDEgMTAuMjcxMiAxMi4zMzIgOC45MTQ2NSAxMi4zMzIgNy41MDAxNkMxMi4zMzIgNi4wODU2NyAxMS43NzAxIDQuNzI5MTIgMTAuNzY5OSAzLjcyODkzQzkuNzY5NzQgMi43Mjg3MyA4LjQxMzE5IDIuMTY2ODMgNi45OTg3IDIuMTY2ODNDNS41ODQyMSAyLjE2NjgzIDQuMjI3NjYgMi43Mjg3MyAzLjIyNzQ2IDMuNzI4OTNDMi4yMjcyNyA0LjcyOTEyIDEuNjY1MzYgNi4wODU2NyAxLjY2NTM2IDcuNTAwMTZDMS42NjUzNiA4LjkxNDY1IDIuMjI3MjcgMTAuMjcxMiAzLjIyNzQ2IDExLjI3MTRDNC4yMjc2NiAxMi4yNzE2IDUuNTg0MjEgMTIuODMzNSA2Ljk5ODcgMTIuODMzNVpNNi45OTg3IDAuODMzNDk2QzcuODc0MTggMC44MzM0OTYgOC43NDEwOCAxLjAwNTkzIDkuNTQ5OTIgMS4zNDA5N0MxMC4zNTg4IDEuNjc2IDExLjA5MzcgMi4xNjcwNiAxMS43MTI3IDIuNzg2MTJDMTIuMzMxOCAzLjQwNTE3IDEyLjgyMjkgNC4xNDAxIDEzLjE1NzkgNC45NDg5NEMxMy40OTI5IDUuNzU3NzggMTMuNjY1NCA2LjYyNDY4IDEzLjY2NTQgNy41MDAxNkMxMy42NjU0IDkuMjY4MjcgMTIuOTYzIDEwLjk2NCAxMS43MTI3IDEyLjIxNDJDMTAuNDYyNSAxMy40NjQ0IDguNzY2ODEgMTQuMTY2OCA2Ljk5ODcgMTQuMTY2OEMzLjMxMjAzIDE0LjE2NjggMC4zMzIwMzEgMTEuMTY2OCAwLjMzMjAzMSA3LjUwMDE2QzAuMzMyMDMxIDUuNzMyMDUgMS4wMzQ0MSA0LjAzNjM2IDIuMjg0NjUgMi43ODYxMkMzLjUzNDkgMS41MzU4NyA1LjIzMDU5IDAuODMzNDk2IDYuOTk4NyAwLjgzMzQ5NlpNNy4zMzIwMyA0LjE2NjgzVjcuNjY2ODNMMTAuMzMyIDkuNDQ2ODNMOS44MzIwMyAxMC4yNjY4TDYuMzMyMDMgOC4xNjY4M1Y0LjE2NjgzSDcuMzMyMDNaIiBmaWxsPSIjRDlEOUQ5Ii8+Cjwvc3ZnPgo=) no-repeat;
      }
    }
  }
}
</style>
