<template>
  <div class="trade-pairs-panel">
    <div class="body">
      <div class="main-box">
        <div class="pairs-table-scroll">
          <div
            v-for="(item, i) in listAvailablePairs"
            :key="i"
            class="tradable"
            :class="{ active: item.name == selectedPairName }"
            @click="handleClickOnPair(item, i)"
          >
            {{ item.pair }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  data: () => ({
    listAvailablePairs: [],
  }),

  computed: {
    ...mapState('Finance', ['selectedPairName']),
  },

  created() {
    this.getAvailablePairs().then(data => {
      this.listAvailablePairs = data
    })
  },

  methods: {
    ...mapActions('Finance', ['getAvailablePairs']),
    ...mapMutations('Finance', ['UPDATE_SELECTED_PAIR']),

    handleClickOnPair(item, i) {
      this.UPDATE_SELECTED_PAIR(item.name)
    },
  },
}
</script>
