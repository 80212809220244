<template>
  <div class="balance-history-panel">
    <div class="header">
      <h2 class="title-h2">Trading Assets</h2>
    </div>
    <div class="body">
      <div class="tabs-history">
        <div
          class="item"
          v-for="(item, key) in tabs"
          :key="key"
          :class="{ active: key == activeTab }"
          @click="activeTab = key"
        >
          {{ item }}
        </div>
      </div>

      <table v-if="activeTab == tabs.statistic" class="balance-history-table">
        <thead>
          <tr>
            <td></td>
            <td>Change</td>
            <td>Balance</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in list" :key="i">
            <td class="balance-history-table__heading">
              {{ item.name }}
            </td>
            <td>
              <ButtonRedGreen
                :status="item.percent > 0"
                :content="item.percent + '%'"
              />
            </td>
            <td>
              <div class="balance">
                {{ item.balance | currency }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tab-history" v-if="activeTab == tabs.history">
        <table>
          <thead>
            <tr>
              <td>Balance</td>
              <td>Date</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in listBalancesComputed" :key="i">
              <td>{{ item.balance | currency }}</td>
              <td>{{ item.date | luxon('d-MM-yyyy') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ButtonRedGreen from '../../components/ButtonRedGreen'
import { calculatePercent } from '../../utils'

export default {
  components: {
    ButtonRedGreen,
  },
  data: () => ({
    activeTab: 'statistic',
    listBalances: [],
  }),

  computed: {
    ...mapState('Finance', ['balanceStats']),

    listBalancesComputed() {
      return [
        {
          balance: this.balanceStats?.now || 0,
          date: new Date().toISOString(),
        },
        ...this.listBalances.slice(1),
      ]
    },

    tabs() {
      return {
        statistic: 'statistic',
        history: 'history',
      }
    },

    list() {
      if (!this.balanceStats) return []

      return [
        {
          name: '24 hours',
          percent: calculatePercent(
            this.balanceStats.yesterday,
            this.balanceStats.now
          ),
          balance: this.balanceStats.now,
        },
        {
          name: '7 days',
          percent: calculatePercent(
            this.balanceStats.lastWeek,
            // this.balanceStats.yesterday
            this.balanceStats.now
          ),
          balance: this.balanceStats.lastWeek,
        },
        {
          name: '30 days',
          percent: calculatePercent(
            this.balanceStats.lastMonth,
            // this.balanceStats.yesterday
            this.balanceStats.now
          ),
          balance: this.balanceStats.lastMonth,
        },
      ]
    },
  },

  created() {
    this.getBalanceStats()

    this.getAppBalanceWithPagination({ limit: 30, sort: { date: -1 } }).then(
      data => {
        this.listBalances = data.docs
      }
    )
  },

  methods: {
    ...mapActions('Finance', [
      'getBalanceStats',
      'getAppBalanceWithPagination',
    ]),
  },
}
</script>
