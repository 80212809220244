<template>
  <div class="trade-banner">
    <div class="trade-banner__container">
      <div class="trade-banner__heading">QB Trading University</div>

      <div class="trade-banner__text">Coming soon!</div>

      <div class="trade-banner__row" v-if="false">
        <a href="" class="trade-banner__link"> Download Reading Material </a>

        <a href="" class="trade-banner__link"> Download Reading Material </a>

        <a href="" class="trade-banner__link">
          Online Learning Using Crypto Content
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeBanner',
}
</script>

<style scoped lang="scss">
.trade-banner {
  border-radius: 8px;
  background: #7c5933;
  color: #fff;
  overflow: hidden;

  &__container {
    padding: 22px 42px 32px 42px;
    background: url('/img/trading-banner-bg.png') center no-repeat;
    background-size: cover;

    @media screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  &__heading {
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: capitalize;
    font-weight: bold;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 22px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__link {
    padding: 14px;
    box-sizing: border-box;
    border-radius: 6px;
    background: #eae3dd;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    width: calc(100% / 3 - 48px / 3);
    transition: all ease 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    pointer-events: none;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:not(:last-child) {
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    //&:hover {
    //  opacity: 0.8;
    //}
  }
}
</style>
