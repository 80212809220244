var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wallet-panel",
    class: {
      hidden: _vm.paginationResult.docs.length == 0
    }
  }, [_c('div', {
    staticClass: "header-wrapper header-wrapper--desktop"
  }, [_vm._m(0), _c('div', {
    staticClass: "deals-header-block"
  }, [_c('div', {
    staticClass: "deals-pagination",
    class: {
      'block-load': _vm.paginationLoad
    }
  }, [_c('pagination', {
    attrs: {
      "page-count": _vm.paginationResult.totalPages,
      "page-range": 3,
      "margin-pages": 0,
      "prev-text": "",
      "next-text": ""
    },
    on: {
      "update": function update($event) {
        return _vm.paginate(_vm.paginationResult.page);
      }
    },
    model: {
      value: _vm.paginationResult.page,
      callback: function callback($$v) {
        _vm.$set(_vm.paginationResult, "page", $$v);
      },
      expression: "paginationResult.page"
    }
  })], 1)])]), _vm._m(1), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "wallet-information"
  }, [_c('table', {
    staticClass: "table-wallet"
  }, [_vm._m(2), _c('tbody', _vm._l(_vm.paginationResult.docs, function (item, i) {
    return _c('tr', {
      key: i,
      class: {
        'buy-row': item.move > 0
      }
    }, [_c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.openTime).toJSON(), 'DD-MM-yyyy')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.openTime).toJSON(), 'HH:mm')) + " ")]), _c('td', [_c('div', {
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(item.move > 0 ? 'buy' : 'sell') + " ")])]), _c('td', [_vm._v(" " + _vm._s(_vm._f("tradeCurrency")(item.size, 10)) + " ")]), _c('td', {
      staticClass: "name"
    }, [_c('cryptoicon', {
      attrs: {
        "symbol": _vm._f("getCurrencyFromPair")(item.pair),
        "size": "22"
      }
    }), _c('span', {
      staticStyle: {
        "margin-left": "10px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("nameToPair")(item.pair)) + " ")])], 1), _c('td', [_vm._v(" " + _vm._s(_vm._f("currency")(item.priceOpen)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.closeTime).toJSON(), 'DD-MM-yyyy')) + " "), _c('br'), _vm._v(" " + _vm._s(_vm._f("moment")(new Date(item.closeTime).toJSON(), 'HH:mm')) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("currency")(item.priceClose)) + " ")]), _c('td', [_c('div', {
      staticClass: "profit",
      class: {
        green: item.profit > 0,
        red: item.profit < 0
      }
    }, [_vm._v(" " + _vm._s(_vm._f("currency")(item.profit)) + " ")])])]);
  }), 0)])])]), _c('div', {
    staticClass: "deals-pagination deals-pagination--bottom",
    class: {
      'block-load': _vm.paginationLoad
    }
  }, [_c('pagination', {
    attrs: {
      "page-count": _vm.paginationResult.totalPages,
      "page-range": 3,
      "margin-pages": 0,
      "prev-text": "",
      "next-text": ""
    },
    on: {
      "update": function update($event) {
        return _vm.paginate(_vm.paginationResult.page);
      }
    },
    model: {
      value: _vm.paginationResult.page,
      callback: function callback($$v) {
        _vm.$set(_vm.paginationResult, "page", $$v);
      },
      expression: "paginationResult.page"
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Deals")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-wrapper header-wrapper--mobile"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h2', {
    staticClass: "title-h2"
  }, [_vm._v("Deals")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('td', [_vm._v("Open time")]), _c('td', [_vm._v("Type")]), _c('td', [_vm._v("Size")]), _c('td', [_vm._v("Pair name")]), _c('td', [_vm._v("Open price")]), _c('td', [_vm._v("Close time")]), _c('td', [_vm._v("Close price")]), _c('td', [_vm._v("profit")])])]);
}]

export { render, staticRenderFns }