<template>
  <div class="bot-terminal-panel">
    <div class="header">
      <div class="header-box">
        <div class="title-h2" style="padding-bottom: 15px">
          Trading Statistics Log
        </div>
      </div>
    </div>
    <div class="body">
      <div class="commands" ref="commandsRef">
        <div class="item" v-for="(item, i) in sortedListMessages" :key="i">
          <div class="time no-wrap">
            {{ item.createdAt | moment('DD-MM-yyyy') }}
            <div style="width: 15px"></div>
            {{ item.createdAt | moment('HH:mm') }}
          </div>
          <div class="details">
            <!-- TODO: Fix replaces -->

            {{ item.msg }}
            <span style="text-transform: capitalize">{{
              item.mark ? item.mark : ''
            }}</span>

            <!-- {{
              $t(item.langKey, getArgs(item.langArgs))
                ?.replace('Sell', '')
                .replace('Buy', '')
            }} -->
            <ButtonRedGreen
              v-if="false"
              :status="item.mark === 'buy'"
              :content="item.mark"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ButtonRedGreen from '../../components/ButtonRedGreen'

export default {
  components: {
    ButtonRedGreen,
  },
  data: () => ({
    listMessages: [],
    botMessages: {
      'bot-message.type.sell': 'Trade type: ',
      'bot-message.type.buy': 'Trade type: ',
      'bot-message.trade': 'Deal opened',
      'bot-message.open-course': 'Opening course: {val}',
      'bot-message.pair': 'Pair: {pair}',
    },
  }),

  mounted() {
    this.getBotTerminalMessages().then(list => {
      this.listMessages = list
      this.scrollToDown()
    })
  },

  computed: {
    sortedListMessages() {
      const sorted = this.listMessages
        .slice()
        .sort((a, b) => this.$moment(a.createdAt) - this.$moment(b.createdAt))
        .map(el => {
          let msg = this.botMessages[el.langKey]
          let args = JSON.parse(el?.langArgs || '{}')
          Object.keys(args).forEach(argKey => {
            msg = msg.replace(`{${argKey}}`, args[argKey])
          })

          let mark = null

          if (el.langKey.endsWith('buy')) mark = 'buy'
          if (el.langKey.endsWith('sell')) mark = 'sell'

          return {
            msg,
            createdAt: el.createdAt,
            mark,
          }
        })
      return sorted
    },
  },

  sockets: {
    'bot-terminal-message'(msg) {
      this.listMessages.push(msg)
      this.scrollToDown()
    },
  },

  methods: {
    ...mapActions('App', ['getBotTerminalMessages']),

    getArgs(json) {
      return json ? JSON.parse(json) : null
    },

    scrollToDown() {
      this.$nextTick(() => {
        this.$refs.commandsRef.scrollTop = this.$refs.commandsRef.scrollHeight
      })
    },
  },
}
</script>
