var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-banner"
  }, [_c('div', {
    staticClass: "trade-banner__container"
  }, [_c('div', {
    staticClass: "trade-banner__heading"
  }, [_vm._v("QB Trading University")]), _c('div', {
    staticClass: "trade-banner__text"
  }, [_vm._v("Coming soon!")]), false ? _c('div', {
    staticClass: "trade-banner__row"
  }, [_c('a', {
    staticClass: "trade-banner__link",
    attrs: {
      "href": ""
    }
  }, [_vm._v(" Download Reading Material ")]), _c('a', {
    staticClass: "trade-banner__link",
    attrs: {
      "href": ""
    }
  }, [_vm._v(" Download Reading Material ")]), _c('a', {
    staticClass: "trade-banner__link",
    attrs: {
      "href": ""
    }
  }, [_vm._v(" Online Learning Using Crypto Content ")])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }