<template>
  <div class="trade-statistic">
    <div
      class="trade-onheader"
      :class="{ hidden: !getterSelectedPairDayStats }"
    >
      <div class="onheader-block">
        <div class="onheader-block__row">
          <div class="onheader-title">24h Change</div>

          <div
            class="onheader-persents red"
            :class="[
              getterSelectedPairDayStats?.priceChangePercent?.includes('-')
                ? 'red'
                : 'green',
            ]"
          >
            {{ getterSelectedPairDayStats?.priceChangePercent }}%
          </div>
        </div>
        <div class="onheader-main">
          {{
            getterSelectedPairDayStats?.priceChange
              | tradeCurrency
              | currencySymbol
          }}
        </div>
      </div>

      <div class="onheader-block">
        <div class="onheader-title">24h High</div>
        <div class="onheader-main">
          ${{ getterSelectedPairDayStats?.highPrice | tradeCurrency }}
        </div>
      </div>

      <div class="onheader-block">
        <div class="onheader-title">24h Low</div>
        <div class="onheader-main">
          ${{ getterSelectedPairDayStats?.lowPrice | tradeCurrency }}
        </div>
      </div>

      <div class="onheader-block">
        <div class="onheader-title">24h Volume (BTC)</div>
        <!-- <div class="onheader-persents green">
          -4.11%
        </div> -->
        <div class="onheader-main">
          {{ getterSelectedPairDayStats?.volume | volume }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TradeStatistic',
  computed: {
    ...mapGetters('Finance', ['getterSelectedPairDayStats']),
  },
}
</script>

<style scoped lang="scss">
.trade-onheader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -8px 0;
  font-weight: bold;
}

.onheader-block {
  border-radius: 8px;
  background: linear-gradient(169deg, #a58763 0%, #7c5933 100%);
  width: calc(50% - 8px);
  height: 100px;
  margin: 8px 0;
  padding: 18px 18px 18px 27px;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
  }
}

.onheader-title {
  color: #e0b27d;
  font-size: 16px;
}

.onheader-main {
  color: #fff;
  font-size: 26px;
}

.onheader-persents {
  padding: 2px 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  background: #a92920;
  margin-left: 10px;

  &.green {
    background: #6cbc51;
    color: #121213;
  }
}
</style>
