<template>
  <div class="trade-block">
    <div class="trade-chart-panel">
      <template>
        <div class="header">
          <!-- Selected pair day stats block -->
          <div class="trade-header">
            <div class="chain">
              <div class="chain__info-box">
                <div class="pair-name">
                  <cryptoicon
                    :symbol="getterSelectedPair.name | getCurrencyFromPair"
                    size="48"
                    v-if="false"
                  />

                  <span>{{ getterSelectedPair.name | nameToPair }}</span>
                </div>
                <div
                  class="pair-price"
                  :class="{ hidden: !currentSelectedPairPrice }"
                >
                  ${{ currentSelectedPairPrice | tradeCurrency }}
                </div>
              </div>
            </div>
            <div class="status">
              <div class="options__box">
                <div class="total-balance" :class="{ hidden: !balanceStats }">
                  Trading Balance
                  <span>{{ balanceStats?.now | currency }}</span>
                </div>
                <div class="options">
                  <div
                    class="no-wrap"
                    v-if="false"
                    :class="showMarkers ? 'item active' : 'item'"
                    @click="showMarkers = !showMarkers"
                    :hidden="disabledMarkers"
                  >
                    Markers: {{ showMarkers ? 'on' : 'off' }}
                  </div>

                  <div
                    class="item item-time"
                    @click="dropPeriodSelect = !dropPeriodSelect"
                  >
                    <span>Time: {{ selectedPeriodCandles }}</span>

                    <!--                    <div-->
                    <!--                      class="btn_time"-->
                    <!--                      v-for="(opt, key) in times"-->
                    <!--                      :key="key"-->
                    <!--                      @click="onUpdatePeriodCandles(opt)"-->
                    <!--                      v-if="favoritePeriodTimes.includes(opt)"-->
                    <!--                      :class="{ active: selectedPeriodCandles == opt }"-->
                    <!--                    >-->
                    <!--                      {{ opt }}-->
                    <!--                    </div>-->

                    <div class="time-selector">
                      <div class="btn_drop">
                        <svg
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.90125 6.43506L9.34375 9.87756L12.7863 6.43506L13.8438 7.50006L9.34375 12.0001L4.84375 7.50006L5.90125 6.43506Z"
                            fill="#121213"
                          />
                        </svg>
                      </div>

                      <div
                        class="time-selector_drop"
                        v-if="dropPeriodSelect"
                        v-click-outside="hideDropPeriodSelect"
                      >
                        <div class="time-selector_drop_header">
                          Select Intervals
                        </div>
                        <div class="time-selector_drop_body">
                          <!--                          @click="onUpdateFavoritePeriod(opt)"-->

                          <div
                            class="time-selector_drop_item"
                            v-for="(opt, key) in times"
                            :key="key"
                            @click="onUpdatePeriodCandles(opt)"
                            :class="{
                              active: selectedPeriodCandles == opt,
                              active: favoritePeriodTimes.includes(opt),
                            }"
                          >
                            <span>
                              {{ opt }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="header__bottom-title"> -->
        <!-- <div class="title"> -->
        <!-- <span>Markets</span> -->
        <!-- </div> -->
        <!-- </div> -->
        <div class="body" style="color: #fff">
          <div class="graphic-col" ref="graphicCol">
            <!--            {{ chartInfo }}-->
            <trading-vue
              :style="graphStyles"
              :data="chart"
              ref="chart"
              :width="widthChart"
              :titleTxt="''"
              colorScale="#22232A"
              colorBack="transparent"
              colorGrid="#22232A"
              colorText="#fff"
              colorCandleUp="#5F8B5B"
              colorCandleDW="#8F413B"
              :timezone="timezoneGMT"
              :overlays="overlays"
            ></trading-vue>

            <!--            <canvas ref="chart" />-->
          </div>
        </div>
      </template>
      <!-- <template v-else>
        <div class="body">
          <UiPreloader />
        </div>
      </template> -->
    </div>

    <TradePairs />
  </div>
</template>

<script>
import TradingVue from 'trading-vue-js'
import TradeGraphMixin from './trade-graph'
import TradePairs from './TradePairs'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Overlays from 'tvjs-overlays'
// import Chart from 'chart.js/auto'

export default {
  components: {
    TradingVue,
    TradePairs,
  },

  mixins: [TradeGraphMixin],

  data: () => ({
    dropPeriodSelect: false,
    chartElement: null,
    overlays: [Overlays['DHistogram']],
  }),

  methods: {
    ...mapMutations('Finance', ['TOGGLE_FAVORITE_PERIOD']),

    onUpdateFavoritePeriod(val) {
      this.TOGGLE_FAVORITE_PERIOD(val)
    },

    hideDropPeriodSelect() {
      this.dropPeriodSelect = false
    },
    addData(chart, label, data) {
      chart.data.labels.push(label)
      chart.data.datasets.forEach(dataset => {
        dataset.data.push(data)
      })
      chart.update()
    },

    removeData(chart) {
      chart.data.labels.pop()
      chart.data.datasets.forEach(dataset => {
        dataset.data.pop()
      })
      chart.update()
    },
  },

  computed: {
    ...mapState('Finance', ['balanceStats', 'favoritePeriodTimes']),
    ...mapGetters('Finance', ['getterSelectedPairDayStats']),
    // chartInfo() {
    //   return this.dataView
    // },
  },

  // mounted() {
  //   // const labels = Chart.Utils.months({ count: 7 })
  //   const gradient = this.$refs.chart
  //     .getContext('2d')
  //     .createLinearGradient(0, 0, 0, 500)
  //   gradient.addColorStop(0, 'rgba(26,26,45,1)')
  //   gradient.addColorStop(1, 'rgba(26,26,45,0)')
  //   /***************/
  //
  //   this.chartElement = new Chart(this.$refs.chart, {
  //     type: 'line',
  //     data: {
  //       labels: [
  //         'January',
  //         'February',
  //         'March',
  //         'April',
  //         'May',
  //         'June',
  //         'July',
  //       ],
  //       datasets: [
  //         {
  //           label: 'My First Dataset',
  //           data: [65, 59, 80, 81, 56, 55, 40],
  //           fill: true,
  //           fillColor: gradient,
  //           backgroundColor: gradient,
  //           borderColor: '#E0B27D',
  //           tension: 0,
  //           borderWidth: 1,
  //         },
  //       ],
  //     },
  //     options: {
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //           grid: {
  //             borderDash: [6, 5],
  //             color: '#545486',
  //           },
  //         },
  //         x: {
  //           grid: {
  //             display: false,
  //           },
  //         },
  //       },
  //     },
  //   })
  // },
}
</script>
