<template>
  <div class="trading-balance-panel">
    <BalanceGraphic />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BalanceGraphic from './BalanceGraphic.vue'

export default {
  components: {
    BalanceGraphic,
  },

  filters: {
    nameToPair: (val, currencies) => {
      return currencies[val]?.pair || name
    },
  },

  computed: {
    ...mapGetters('Finance', ['getterFuturesPrices']),
    ...mapGetters('Finance', ['getterPairsMarkersByLimit']),

    tableMarkersData() {
      return this.getterPairsMarkersByLimit(5).reverse()
    },
  },
}
</script>

<style lang="scss">
.trading-balance-panel {
  padding: 18px 28px 26px 28px;
  border-radius: 8px;
  background: #313151;
  margin-top: 30px;
  -webkit-box-shadow: 0px 4px 29px 0px rgba(28, 28, 47, 1);
  -moz-box-shadow: 0px 4px 29px 0px rgba(28, 28, 47, 1);
  box-shadow: 0px 4px 29px 0px rgba(28, 28, 47, 1);

  .header {
    .wallet-box {
      .title {
        .title-h2 {
          font-weight: 700;
          font-size: 30px;
          color: #ffffff;
          padding: 0 0 30px;

          @media screen and (max-width: 767px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>
