<template>
  <div
    class="wallet-panel"
    :class="{ hidden: paginationResult.docs.length == 0 }"
  >
    <div class="header-wrapper header-wrapper--desktop">
      <div class="header">
        <h2 class="title-h2">Deals</h2>
      </div>
      <div class="deals-header-block">
        <div class="deals-pagination" :class="{ 'block-load': paginationLoad }">
          <pagination
            v-model="paginationResult.page"
            @update="paginate(paginationResult.page)"
            :page-count="paginationResult.totalPages"
            :page-range="3"
            :margin-pages="0"
            prev-text=""
            next-text=""
          />
        </div>

        <!--        <button class="deals-export" v-if="percentDone">-->
        <!--          {{ percentDone }}%-->
        <!--        </button>-->
        <!--        <button class="deals-export" v-else @click="handleExportHistory">-->
        <!--          Export-->
        <!--        </button>-->
      </div>
    </div>
    <div class="header-wrapper header-wrapper--mobile">
      <div class="header">
        <h2 class="title-h2">Deals</h2>
      </div>
      <!--      <div class="deals-header-block">-->
      <!--        <button class="deals-export" v-if="percentDone">-->
      <!--          {{ percentDone }}%-->
      <!--        </button>-->
      <!--        <button class="deals-export" v-else @click="handleExportHistory">-->
      <!--          Export-->
      <!--        </button>-->
      <!--      </div>-->
    </div>

    <div class="body">
      <div class="wallet-information">
        <table class="table-wallet">
          <thead>
            <tr>
              <!--              <td>DEAL NUMBER</td>-->
              <td>Open time</td>
              <td>Type</td>
              <td>Size</td>
              <td>Pair name</td>
              <td>Open price</td>
              <td>Close time</td>
              <td>Close price</td>
              <td>profit</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in paginationResult.docs"
              :key="i"
              :class="{ 'buy-row': item.move > 0 }"
            >
              <!--              <td>{{ item._id | mongoIdToNumber }}</td>-->
              <td>
                {{ new Date(item.openTime).toJSON() | moment('DD-MM-yyyy') }}
                <!--                &nbsp;&nbsp;&nbsp;-->
                <br />
                {{ new Date(item.openTime).toJSON() | moment('HH:mm') }}
              </td>
              <td>
                <div style="text-transform: capitalize">
                  <!--                  <ButtonRedGreen-->
                  <!--                    :status="item.move > 0"-->
                  <!--                    :content="item.move > 0 ? 'buy' : 'sell'"-->
                  <!--                  />-->
                  {{ item.move > 0 ? 'buy' : 'sell' }}
                </div>
              </td>
              <td>
                {{ item.size | tradeCurrency(10) }}
              </td>
              <td class="name">
                <cryptoicon
                  :symbol="item.pair | getCurrencyFromPair"
                  size="22"
                />

                <span style="margin-left: 10px">
                  {{ item.pair | nameToPair }}
                </span>
              </td>
              <td>
                {{ item.priceOpen | currency }}
              </td>
              <td>
                {{ new Date(item.closeTime).toJSON() | moment('DD-MM-yyyy') }}
                <!--                &nbsp;&nbsp;&nbsp;-->
                <br />
                {{ new Date(item.closeTime).toJSON() | moment('HH:mm') }}
              </td>
              <td>
                {{ item.priceClose | currency }}
              </td>
              <td>
                <!-- <div class="profit"
                  :class="{ 'green': item.profit > 0.01, 'red': item.profit > -0.01 && item.profit < 0 }">
                  {{ item.profit | tradeCurrency }}
                </div> -->

                <div
                  class="profit"
                  :class="{ green: item.profit > 0, red: item.profit < 0 }"
                >
                  {{ item.profit | currency }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="deals-pagination deals-pagination--bottom"
      :class="{ 'block-load': paginationLoad }"
    >
      <pagination
        v-model="paginationResult.page"
        @update="paginate(paginationResult.page)"
        :page-count="paginationResult.totalPages"
        :page-range="3"
        :margin-pages="0"
        prev-text=""
        next-text=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ButtonRedGreen from '../../components/ButtonRedGreen'
import ExportHistoryMixin from './export-history.mixin'

export default {
  name: 'Deals',

  mixins: [ExportHistoryMixin],

  components: {
    ButtonRedGreen,
  },

  data: () => ({
    paginationLoad: false,
    paginationResult: {
      docs: [],
      totalDocs: 0,
      totalPages: 0,
      page: 1,
      limit: 12,
    },
  }),

  watch: {
    selectedPairName() {
      this.paginate(1)
    },
  },

  sockets: {
    'update-markers'({ list }) {
      if (list?.[0]?.pair == this.selectedPairName) this.paginate()
    },
  },

  mounted() {
    this.paginate(1)
  },

  computed: {
    ...mapState('Finance', ['selectedPairName']),
  },

  methods: {
    ...mapActions('Finance', ['getDealsWithPagination']),

    paginate(page) {
      this.paginationLoad = true

      if (page) this.paginationResult.page = page

      this.getDealsWithPagination({
        pagination: {
          page: this.paginationResult.page,
          limit: this.paginationResult.limit,
          sort: { closeTime: -1 },
        },
        pair: this.selectedPairName,
      })
        .then(data => (this.paginationResult = data))
        .finally(() => (this.paginationLoad = false))
    },
  },
}
</script>
