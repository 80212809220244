var render = function () {
  var _vm$balanceStats;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-block"
  }, [_c('div', {
    staticClass: "trade-chart-panel"
  }, [[_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "trade-header"
  }, [_c('div', {
    staticClass: "chain"
  }, [_c('div', {
    staticClass: "chain__info-box"
  }, [_c('div', {
    staticClass: "pair-name"
  }, [false ? _c('cryptoicon', {
    attrs: {
      "symbol": _vm._f("getCurrencyFromPair")(_vm.getterSelectedPair.name),
      "size": "48"
    }
  }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm._f("nameToPair")(_vm.getterSelectedPair.name)))])], 1), _c('div', {
    staticClass: "pair-price",
    class: {
      hidden: !_vm.currentSelectedPairPrice
    }
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")(_vm.currentSelectedPairPrice)) + " ")])])]), _c('div', {
    staticClass: "status"
  }, [_c('div', {
    staticClass: "options__box"
  }, [_c('div', {
    staticClass: "total-balance",
    class: {
      hidden: !_vm.balanceStats
    }
  }, [_vm._v(" Trading Balance "), _c('span', [_vm._v(_vm._s(_vm._f("currency")((_vm$balanceStats = _vm.balanceStats) === null || _vm$balanceStats === void 0 ? void 0 : _vm$balanceStats.now)))])]), _c('div', {
    staticClass: "options"
  }, [false ? _c('div', {
    staticClass: "no-wrap",
    class: _vm.showMarkers ? 'item active' : 'item',
    attrs: {
      "hidden": _vm.disabledMarkers
    },
    on: {
      "click": function click($event) {
        _vm.showMarkers = !_vm.showMarkers;
      }
    }
  }, [_vm._v(" Markers: " + _vm._s(_vm.showMarkers ? 'on' : 'off') + " ")]) : _vm._e(), _c('div', {
    staticClass: "item item-time",
    on: {
      "click": function click($event) {
        _vm.dropPeriodSelect = !_vm.dropPeriodSelect;
      }
    }
  }, [_c('span', [_vm._v("Time: " + _vm._s(_vm.selectedPeriodCandles))]), _c('div', {
    staticClass: "time-selector"
  }, [_c('div', {
    staticClass: "btn_drop"
  }, [_c('svg', {
    attrs: {
      "viewBox": "0 0 19 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.90125 6.43506L9.34375 9.87756L12.7863 6.43506L13.8438 7.50006L9.34375 12.0001L4.84375 7.50006L5.90125 6.43506Z",
      "fill": "#121213"
    }
  })])]), _vm.dropPeriodSelect ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.hideDropPeriodSelect,
      expression: "hideDropPeriodSelect"
    }],
    staticClass: "time-selector_drop"
  }, [_c('div', {
    staticClass: "time-selector_drop_header"
  }, [_vm._v(" Select Intervals ")]), _c('div', {
    staticClass: "time-selector_drop_body"
  }, _vm._l(_vm.times, function (opt, key) {
    var _class;

    return _c('div', {
      key: key,
      staticClass: "time-selector_drop_item",
      class: (_class = {
        active: _vm.selectedPeriodCandles == opt
      }, _class["active"] = _vm.favoritePeriodTimes.includes(opt), _class),
      on: {
        "click": function click($event) {
          return _vm.onUpdatePeriodCandles(opt);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(opt) + " ")])]);
  }), 0)]) : _vm._e()])])])])])])]), _c('div', {
    staticClass: "body",
    staticStyle: {
      "color": "#fff"
    }
  }, [_c('div', {
    ref: "graphicCol",
    staticClass: "graphic-col"
  }, [_c('trading-vue', {
    ref: "chart",
    style: _vm.graphStyles,
    attrs: {
      "data": _vm.chart,
      "width": _vm.widthChart,
      "titleTxt": '',
      "colorScale": "#22232A",
      "colorBack": "transparent",
      "colorGrid": "#22232A",
      "colorText": "#fff",
      "colorCandleUp": "#5F8B5B",
      "colorCandleDW": "#8F413B",
      "timezone": _vm.timezoneGMT,
      "overlays": _vm.overlays
    }
  })], 1)])]], 2), _c('TradePairs')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }