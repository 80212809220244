<template>
  <div class="header">
    <div class="wallet-box">
      <div class="title flex-amount-balance-title">
        <h2 class="title-h2">Amount of capital</h2>

        <div class="select-history-range" v-if="false">
          <button
            v-for="t in blanceGraphicTimes || []"
            :key="t"
            :class="{ active: selectedBalanceGraphicTime == t }"
            @click="onChangeTime(t)"
          >
            {{ t }}
          </button>
        </div>
      </div>
    </div>

    <div>
      <apexchart
        type="radialBar"
        height="332px"
        ref="chart"
        :options="chartOptions"
        :series="series"
      />

      <!--      <div ref="chart" />-->
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapMutations, mapState } from 'vuex'
import { DateTime } from 'luxon'

export default {
  data: () => ({
    daysBalances: [],
    series: [44, 55],
    chartOptions: {
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
              color: '#fff',
            },
            value: {
              fontSize: '16px',
              color: 'rgba(189,189,189,0.8)',
            },
            // style: {
            //   colors: ['#fff', '#fff'],
            // },
            total: {
              show: true,
              fontSize: '22px',
              color: '#fff',
              //   label: 'Total',
              // formatter: function (w) {
              //   // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
              //   return 249
              // },
            },
          },
          track: {
            background: 'rgba(189,189,189,0.2)',
            strokeWidth: '50%',
            margin: 8,
          },
        },
      },
      fill: {
        colors: ['#75A2FF', '#CA9C53'],
      },
      labels: ['BTC', 'ETH'],
    },
  }),

  components: {
    apexchart: VueApexCharts,
  },

  methods: {
    ...mapActions('Finance', ['getAppBalanceWithPagination']),
    ...mapMutations('Finance', ['UPDATE_SELECTED_BALANCE_GRAPHIC_TIME']),

    onChangeTime(t) {
      this.UPDATE_SELECTED_BALANCE_GRAPHIC_TIME(t)
    },
  },

  created() {
    this.getAppBalanceWithPagination({ limit: 30, sort: { date: -1 } }).then(
      data => {
        this.daysBalances = data.docs.map(e => ({
          balance: e.balance,
          date: DateTime.fromISO(e.date).toFormat('dd.MM.yyyy'),
          tm: DateTime.fromISO(e.date).toMillis(),
        }))
      }
    )
  },

  computed: {
    ...mapState('Finance', [
      'balanceStats',
      'blanceGraphicTimes',
      'selectedBalanceGraphicTime',
    ]),

    compDataChart() {
      let data = [
        ...this.daysBalances,
        {
          balance: this.balanceStats?.now || 0,
          date: DateTime.now().toFormat('dd.MM.yyyy'),
          tm: DateTime.now().toMillis(),
        },
      ].sort((a, b) => a.tm - b.tm)

      if (this.selectedBalanceGraphicTime == '7d')
        data = data.slice(-7).filter((e, i) => !(i % 2))
      else if (this.selectedBalanceGraphicTime == '30d') {
        data = [data[0], data[10], data[20], data[30]].filter(e => e)
      }
      return data
    },

    // series() {
    //   if (!this.daysBalances.length) return []
    //
    //   return [
    //     {
    //       name: 'balance',
    //       data: this.compDataChart.map(e => e.balance),
    //     },
    //   ]
    // },

    chartData() {
      return {
        options: {
          // type: 'line',
          // type: 'area',
          colors: ['#E9BD42'],
          grid: {
            show: true,
            borderColor: process.env.VUE_APP_GRID_COLOR,
          },
          chart: {
            type: 'area',

            id: 'apexchart-indicators',
            foreColor: '#9f9fa1',
            animations: {
              enabled: true,
              easing: 'linear',
              dynamicAnimation: {
                speed: 1000,
              },
            },

            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },

          markers: {
            size: 3,
            hover: {
              size: 5,
            },
          },

          stroke: {
            curve: 'smooth',
            show: true,
            lineCap: 'butt',
            colors: undefined,
            width: 3,
            dashArray: 0,
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: val => this.$options.filters.currency(val),
            },
          },

          yaxis: {
            show: true,

            labels: {
              formatter: val => this.$options.filters.currency(val),
            },
          },

          xaxis: {
            labels: {
              show: true,
              rotate: -20,
            },

            categories: this.compDataChart.map(e => e.date),

            axisBorder: {
              show: false,
            },

            axisTicks: {
              show: false,
            },
          },
        },
        series: this.series,
      }
    },
  },
}
</script>
