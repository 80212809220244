var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-pairs-panel"
  }, [_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "main-box"
  }, [_c('div', {
    staticClass: "pairs-table-scroll"
  }, _vm._l(_vm.listAvailablePairs, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "tradable",
      class: {
        active: item.name == _vm.selectedPairName
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickOnPair(item, i);
        }
      }
    }, [_vm._v(" " + _vm._s(item.pair) + " ")]);
  }), 0)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }