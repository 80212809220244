<template>
  <span
    class="status__btn"
    :class="[
      status === false ? 'red' : 'green',
      content.endsWith('%') === true ? 'arrow' : '',
    ]"
  >
    {{ content }}
  </span>
</template>

<script>
export default {
  name: 'ButtonRedGreen',
  props: {
    content: String,
    status: Boolean,
  },
}
</script>
