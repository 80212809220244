var render = function () {
  var _vm$getterSelectedPai, _vm$getterSelectedPai2, _vm$getterSelectedPai3, _vm$getterSelectedPai4, _vm$getterSelectedPai5, _vm$getterSelectedPai6, _vm$getterSelectedPai7;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trade-statistic"
  }, [_c('div', {
    staticClass: "trade-onheader",
    class: {
      hidden: !_vm.getterSelectedPairDayStats
    }
  }, [_c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-block__row"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v("24h Change")]), _c('div', {
    staticClass: "onheader-persents red",
    class: [(_vm$getterSelectedPai = _vm.getterSelectedPairDayStats) !== null && _vm$getterSelectedPai !== void 0 && (_vm$getterSelectedPai2 = _vm$getterSelectedPai.priceChangePercent) !== null && _vm$getterSelectedPai2 !== void 0 && _vm$getterSelectedPai2.includes('-') ? 'red' : 'green']
  }, [_vm._v(" " + _vm._s((_vm$getterSelectedPai3 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai3 === void 0 ? void 0 : _vm$getterSelectedPai3.priceChangePercent) + "% ")])]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" " + _vm._s(_vm._f("currencySymbol")(_vm._f("tradeCurrency")((_vm$getterSelectedPai4 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai4 === void 0 ? void 0 : _vm$getterSelectedPai4.priceChange))) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v("24h High")]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")((_vm$getterSelectedPai5 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai5 === void 0 ? void 0 : _vm$getterSelectedPai5.highPrice)) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v("24h Low")]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" $" + _vm._s(_vm._f("tradeCurrency")((_vm$getterSelectedPai6 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai6 === void 0 ? void 0 : _vm$getterSelectedPai6.lowPrice)) + " ")])]), _c('div', {
    staticClass: "onheader-block"
  }, [_c('div', {
    staticClass: "onheader-title"
  }, [_vm._v("24h Volume (BTC)")]), _c('div', {
    staticClass: "onheader-main"
  }, [_vm._v(" " + _vm._s(_vm._f("volume")((_vm$getterSelectedPai7 = _vm.getterSelectedPairDayStats) === null || _vm$getterSelectedPai7 === void 0 ? void 0 : _vm$getterSelectedPai7.volume)) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }