var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-header"
  }, [_c('div', {
    staticClass: "site-title"
  }, [_vm._v(" Realtime live trading monitoring ")]), _c('div', {
    staticClass: "header-date-time"
  }, [_c('div', {
    staticClass: "date"
  }, [_c('div', {
    staticClass: "icon"
  }), _vm._v(" " + _vm._s(_vm._f("moment")(_vm.time, 'DD-MM-yyyy')) + " ")]), _c('div', {
    staticClass: "time"
  }, [_c('div', {
    staticClass: "icon"
  }), _vm._v(" " + _vm._s(_vm._f("moment")(_vm.time, 'HH:mm')) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }