<template>
  <div class="trade-page">
    <Header v-if="false" />

    <div class="general-box">
      <div class="left-wrap-col">
        <TradeGraphicPanel />

        <TradeBanner />

        <div class="body">
          <Deals />
        </div>
      </div>

      <div class="right-wrap-col">
        <TradeStatistic />

        <BalanceHistoryPanel />

        <Wallet />

        <BotTerminal />
      </div>
    </div>
  </div>
</template>

<script>
import Wallet from './Wallet.vue'
import TradeGraphicPanel from './TradeGraphicPanel.vue'
import BotTerminal from './BotTerminal.vue'
import Deals from './Deals'
import BalanceHistoryPanel from './BalanceHistoryPanel.vue'
import Header from './Header'
import TradeStatistic from '@/views/trade/TradeStatistic.vue'
import TradeBanner from '@/views/trade/TradeBanner.vue'

export default {
  components: {
    TradeBanner,
    TradeStatistic,
    Wallet,
    TradeGraphicPanel,
    BotTerminal,
    BalanceHistoryPanel,
    Deals,
    Header,
  },
}
</script>

<style lang="scss" src="./styles/src.scss"></style>
