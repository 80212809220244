var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bot-terminal-panel"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    ref: "commandsRef",
    staticClass: "commands"
  }, _vm._l(_vm.sortedListMessages, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "item"
    }, [_c('div', {
      staticClass: "time no-wrap"
    }, [_vm._v(" " + _vm._s(_vm._f("moment")(item.createdAt, 'DD-MM-yyyy')) + " "), _c('div', {
      staticStyle: {
        "width": "15px"
      }
    }), _vm._v(" " + _vm._s(_vm._f("moment")(item.createdAt, 'HH:mm')) + " ")]), _c('div', {
      staticClass: "details"
    }, [_vm._v(" " + _vm._s(item.msg) + " "), _c('span', {
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(_vm._s(item.mark ? item.mark : ''))]), false ? _c('ButtonRedGreen', {
      attrs: {
        "status": item.mark === 'buy',
        "content": item.mark
      }
    }) : _vm._e()], 1)]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-box"
  }, [_c('div', {
    staticClass: "title-h2",
    staticStyle: {
      "padding-bottom": "15px"
    }
  }, [_vm._v(" Trading Statistics Log ")])])]);
}]

export { render, staticRenderFns }